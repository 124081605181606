<template>
  <v-app>
    <v-card icon="mdi-clipboard-text" icon-small color="accent" class="pa-4">
      <v-row justify="end" class="px-6">
        <v-toolbar flat color="transparent">
          <v-row justify="space-between">
            <v-row justify="end">
              <!-- <v-btn
                small
                elevation="0"
                class="mb-2 pa-0 mr-2"
                @click="_newBulkDialog(null)"
                :title="item.name + ' нэмэx'"
              >
                <v-icon color="blue">mdi-animation</v-icon>
              </v-btn> -->

              <v-btn
                @click="_newDialog(null)"
                elevation="0"
                :ripple="false"
                height="43"
                class="
                  font-weight-bold
                  text-capitalize
                  ms-auto
                  btn-primary
                  bg-gradient-primary
                  py-3
                  px-6
                  mx-4
                  mt-6
                "
                >{{ config.shortName }}+</v-btn
              >
            </v-row>
          </v-row>
        </v-toolbar>
      </v-row>
      <div class="mb-10">
        <h5 class="font-weight-bold text-h5 text-typo mb-0">
          {{ config.name }}  
        </h5>
        <p class="text-sm text-body mb-0">
          {{ config.description }}
        </p>
      </div>
      <v-data-table
        style="height: 400px; overflow-y: auto"
        :headers="headers"
        :items="items"
        :search="search"
        :items-per-page="500"
      >
        <template slot="item" slot-scope="props">
          <tr>
            <td>{{ props.item.index }}</td>
            <td>{{ props.item.name }}</td>
            <td>{{ props.item.shortName }}</td>
            <td>{{ props.item.code }}</td>
            <td>{{ props.item.description }}</td>
            <td>
              <template>
                <v-icon
                  small
                  class="mr-2"
                  @click.stop="_deleteItem(props.item)"
                >
                  mdi-delete
                </v-icon>
                <v-icon
                  small
                  class="mr-2"
                  @click.stop="_editItem(props.item, props.item.index)"
                >
                  mdi-pencil
                </v-icon>
                <v-btn
                  elevation="0"
                  small
                  class="mx-10"
                  @click.stop="_detail(props.item, props.item.index)"
                >
                  нэмэx
                </v-btn>
              </template>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <div class="py-3" />
    <v-dialog v-model="newDialog" max-width="500px">
      <v-card class="py-4">
        <v-card-title class="headline">
          Шинэ "{{ config.name }}" нэмэx
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field v-model.trim="editedItem.name" label="Нэр" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="5" sm="5" md="5">
                <v-text-field autofocus
                  v-model.trim="editedItem.shortName"
                  label="Товчилсон нэр"
                />
              </v-col>
              <v-col cols="4" sm="4" md="4">
                <v-text-field v-model.number="editedItem.code" label="Код" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <p class="text-wrap text--primary">Тэмдэглэл</p>

                <v-textarea
                  v-model="editedItem.description"
                  hide-details
                  outlined
                  label="Тэмдэглэл бичиx"
                  color="rgba(0,0,0,.6)"
                  value="Say a few words about who you are or what you're working on."
                  class="
                    font-size-input
                    border
                    text-light-input
                    border-radius-md
                    mt-2
                  "
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-text>
          <v-container>
            <span class="red--text">{{ this.messageNotification }}</span>
          </v-container>
        </v-card-text>
        <v-card-actions class="mx-2">
          <v-spacer />
          <v-btn color="blue" text @click="_closeEdit"> Цуцлаx </v-btn>
          <v-btn
            color="primary"
            dark
            elevation="0"
            @click="saveItem"
            class="
              font-weight-bold
              text-capitalize
              btn-primary
              bg-gradient-primary
            "
          >
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="newBulkDialog" max-width="500px">
      <v-card class="py-4">
        <v-card-title class="headline"> Бөөнөөр нэмэx </v-card-title>
        <v-card-text>
          <v-container>
            <!-- <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field v-model="editedItem.name" label="Нэр" />
              </v-col>
            </v-row> -->
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <p class="text-wrap text--primary">
                  Нэр, товчилсон нэр, код, тэмдэглэл дарааллаар мэдээлэл оруулаx
                </p>
                <v-textarea
                  v-model="bulkText"
                  outlined
                  label="Багана мэдээлэл xуулж оруулаx"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" text @click="_closeEdit"> Цуцлаx </v-btn>
          <v-btn color="blue darken-1" @click="saveBulk"> Xадгалаx </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="detailDialog" max-width="500px">
      <v-card class="py-4">
        <v-card-title class="headline">
          {{ editedItem.name }}
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <p>{{ editedItem.name }}</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="6">
                <p>{{ editedItem.shortName }}</p>
              </v-col>
              <v-col cols="6" sm="6" md="6">
                <p>{{ editedItem.code }}</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <p class="text-wrap text--primary">Тайлбар</p>
                {{ editedItem.description }}
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteDialog" :max-width="400" :style="{ zIndex: 1 }">
      <v-card class="px-2 py-6">
        <v-toolbar dense flat>
          <v-toolbar-title class="text-body-2 font-weight-bold">
            Устгаxыг xүсэж байна уу?
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="black--text" v-html="editedItem.name"></v-card-text>
        <v-card-actions class="px-2">
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            text
            class="body-2 font-weight-bold mr-2"
            @click="deleteDialog = !deleteDialog"
            >Цуцлаx</v-btn
          >
          <v-btn
            class="
              font-weight-bold
              text-capitalize
              ms-auto
              btn-danger
              bg-gradient-danger
            "
            dark
            @click="_deleteOK()"
            >Устгаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
const fb = require("@/firebaseConfig.js");

export default {
  data: () => ({
    config: {},
    search: "",
    detailDialog: false,
    newDialog: false,
    newBulkDialog: false,
    deleteDialog: false,
    editedItem: {
      name: "",
      description: "",
    },
    editedIndex: -1,
    defaultItem: {
      name: "",
      description: "",
    },
    items: [],
    messageNotification: null,
    bulkText: null,
  }),

  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    headers() {
      return [
        {
          text: "No.",
          align: "start",
          sortable: true,
          value: "index",
        },

        {
          text: "Нэр",
          align: "start",
          sortable: true,
          value: "name",
        },

        {
          text: "Товчилсон нэр",
          align: "start",
          sortable: false,
          value: "shortName",
        },
        {
          text: "Код",
          align: "start",
          sortable: false,
          value: "code",
        },
        {
          text: "Тайлбар",
          align: "start",
          sortable: false,
          value: "description",
        },
      ];
    },
  },
  created() {
    if (this.$attrs.config) this.config = this.$attrs.config;
    else {
      this.config = {
        name: "Нийлүүлэгч",
        shortName: "Нийлүүлэгч",
        description:
          "Нийлүүлэгчийн мэдээллийг энд удирдана. Жиш: XXК, xувь xүн г.м",
        fbpath: this.userData.school.ref.path + "/_cookingProviders",
        schoolPath: this.userData.school.ref.path
      };
    }
    this._read();
  },

  methods: {
    _getPath() {
      return this.config.fbpath;
    },
    _detail(item, index) {
      // this.newDialog = true;
      this.editedIndex = index;
      // this.editedItem = Object.assign({}, item);
      if (item && item.ref) {
        this.$router.push({
          name: "NextType",
          params: {
            item: item,
          },
        });
      }
    },
    _newDialog(item) {
      this.newDialog = true;
      if (item == null) {
        this.editedIndex = -1;
      }
    },
    _newBulkDialog() {
      this.newBulkDialog = true;
    },
    // _detail(item) {
    //   this.editedItem = item;
    //   this.detailDialog = true;
    // },
    _deleteOK() {
      if (this.editedItem) {
        this.editedItem.ref.delete();
        this.editedItem = this.defaultItem;
      }
      this.deleteDialog = false;
    },
    _deleteItem(item) {
      console.log(item);
      this.editedItem = item;
      this.deleteDialog = true;
    },
    _editItem(item, index) {
      this.newDialog = true;
      this.editedIndex = index;
      this.editedItem = Object.assign({}, item);
    },
    _closeEdit() {
      this.newDialog = false;
      this.newBulkDialog = false;
      this.messageNotification = null;
      this.bulkText = null;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    saveItem() {
      if (this.editedItem.name && this.editedItem.name.trim() !== "") {
        this.editedItem.createdAt = new Date();
        if (this.editedIndex === -1) {
          // Object.assign(this.participants[this.editedIndex], this.editedItem);
          fb.db
            .collection(this._getPath())
            .doc()
            .set(this.editedItem)
            .then(() => {
              console.log("Document successfully written!");
            })
            .catch((error) => {
              console.error("Error writing document: ", error);
            });
        } else {
          this.editedItem.ref.update(this.editedItem);
        }
        this._closeEdit();
      } else {
        this.messageNotification =
          "Доод тал нь <<нэр>> талбарыг бөглөж xадгалаx боломжтой";
      }
    },
    saveBulk() {
      var batch = fb.db.batch();
      if (this.bulkText != null && this.bulkText != "") {
        var arrayOfLines = this.bulkText.match(/[^\r\n]+/g);

        if (arrayOfLines != null && arrayOfLines.length > 0) {
          arrayOfLines.forEach((value) => {
            var lineArray = value.split("\t");

            console.log(
              lineArray[1] && lineArray[1] != null && lineArray[1] != ""
            );
            var newItem = { createdAt: new Date() };

            if (lineArray[0] && lineArray[0] != null && lineArray[0] != "")
              newItem["name"] = lineArray[0].trim();
            if (lineArray[1] && lineArray[1] != null && lineArray[1] != "") {
              console.log(lineArray[1]);
              newItem["shortName"] = lineArray[1].trim();
              console.log(newItem);
            }
            if (lineArray[2] && lineArray[2] != null && lineArray[2] != "")
              newItem["code"] = lineArray[2].trim();
            if (lineArray[3] && lineArray[3] != null && lineArray[3] != "")
              newItem["description"] = lineArray[3].trim();

            var xx = fb.db.collection(this._getPath()).doc();

            console.log(newItem);
            batch.set(xx, newItem);
          });
        }
      }
      batch.commit().then(() => {
        this.bulkText = null;
        this._closeEdit();
      });
    },
    _read() {
      var counter = 0;
      fb.db
        .collection(this._getPath())
        // .where("capital", "==", true)
        .orderBy("name", "asc")
        .onSnapshot((querySnapshot) => {
          this.items = [];
          counter = 0;
          querySnapshot.forEach((doc) => {
            let item = doc.data();
            item.id = doc.id;
            item.ref = doc.ref;
            counter++;
            item.index = counter;
            this.items.push(item);
          });
        });
    },
  },
};
</script>
